<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: left">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="公司名称：">
          <el-input v-model="queryForm.name" placeholder="请输入公司名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <div>
        <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        fit
        style="width: 100%">
        <el-table-column  type="index"  label="序号" />
        <el-table-column :show-overflow-tooltip="true" prop="accountName"  label="公司名称" /> 
        <el-table-column prop="walletId" label="子账号号码" />
        <el-table-column label="资金余额(元)" align="center">
          <template slot-scope="scope" >{{accDiv(scope.row.amount,100)}}</template>
        </el-table-column> 
        <el-table-column prop="state" label="状态" align="center"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 'auditing'">待生效</span>
            <span v-if="scope.row.state == 'succeeded'">已生效</span>
            <span v-if="scope.row.state == 'failed'">失效</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <el-button @click="noDetails(scope.row)" type="text" size="small">资金流水</el-button> 
            <el-button type="text" size="small" @click="openUploadFrom(scope.row)">资金清零</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      
      id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20,50]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <!-- 外层 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;">
          <!-- 内层 -->
          <el-form :model="subaccount"  ref="subaccount" label-width="100px" class="subaccount" >
              <el-form-item label="公司名称：">
                <el-input v-model="subaccount.tenantName" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="金额：">
                <el-input v-model="subaccount.amount" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input v-model="subaccount.transferComment"></el-input>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitUploadFrom">确 定</el-button>
          </div>
        </el-dialog>
    </div>

    
  </div>
</template>

<script>

export default {
    data() {
      return {
        title:'资金清零',
        dialogFormVisible: false,
        summaryStateList: null,
        tableLoading: true,
        loadDate:'',
        tableData: [],
        logTableData: null,
        input: '',
        queryForm: {
          id: '',
          idno: '',
          name: '',
          account:'',
          pageNo: 1,
          pageSize: 10,
          sessionId: this.$getCookie("sessionId"),
        },
        subaccount:{
          tenant:'',
          tenantName:'',
          amount:'',
          transferComment:''
        },

        total:0,
      }
    },
    async created(){
      // 获取数组字典
      await this.loadTable();
    },
    methods: {
      // 加载表格
      async loadTable(){
        // .bind(this);
        let flag = this;
        await this.api.accountApi.fundAccountList(this.queryForm).then((res) => {
				res = res.data
				if(res.code == 200){
					flag.tableData = res.data.data.records;
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
    },
        //资金清零
    async submitUploadFrom() {
      this.tableLoading = true;
      await this.api.salaryApi.clearSubAccountToZero(this.subaccount).then(res => {
        console.log("-----",res)
        if(res.data.code==200){
          this.$message.success("操作成功")
          this.dialogFormVisible = false;
        }else{
          this.$message.error(res.data.msg);
        }
        });
    },

    openUploadFrom(row){
      this.dialogFormVisible = true;
      this.subaccount.tenant = row.tenantId
      this.subaccount.tenantName = row.accountName;
      this.subaccount.amount = row.amount/100;
    },


    // 查看函数
    noDetails(row){
        this.$router.push({path: '/tran',query: {tenantId: row.tenantId}})
    },
      // async exportTable(){
      //   console.log("导出下载");
      //   await this.api.salaryApi.downloadSubaccountLog().then(res =>{
      //     console.log("导出下载----",res)
      //     let fileNameEncode = "子账号数据.xlsx";
      //     const link = document.createElement("a");
      //     let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
      //     console.log(blob);
      //     link.style.display = "none";
      //     link.href = URL.createObjectURL(blob);
      //     link.setAttribute("download",  decodeURIComponent(fileNameEncode));
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //   })
      // },

      // 条件查询
      onQuery() {
        this.loadTable();
      },
      
      // 重置表单
      resetForm(form){
        this.$refs[form].resetFields();
      },
      // 重置
      onReset(){
        this.queryForm.name='';
        this.queryForm.account='';
      },
      //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
    }
}
</script>

<style scoped>



#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}

</style>
